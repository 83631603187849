.plans-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 2rem;
    margin-top: -4rem;
    position: relative;
}

.plans-blur-1{
    width: 100rem;
    height: 30rem;
    top: 6rem;
    left: 10rem;
}

.programs-header{
    display: flex;
    margin-top: 2rem;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    font-style: italic;
    
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan {
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: #fff;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan:hover { 
    background: var(--planCard);
    transform: scale(1.1);
}

.plan>svg {
    fill: var(--orange);
    width: 2rem;
}

.plan>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}

.plan>:nth-child(5) {
    font-size: 0.8rem;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img {
    width: 1rem;
}

.plans>:nth-child(1)>svg{
    fill: #ac712e;
}

.plans>:nth-child(2)>svg{
    fill: #d4d4d4;
}

.plans>:nth-child(3)>svg{
    fill: #e7be06;
}


.plans>:nth-child(2)>button{
    color: var(--orange);
}

.btn-fatures{
    background-color: #fff;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-fatures > a {
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .programs-header{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size:x-large;
    }
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}