.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links > a{
    display: flex;
    gap: 1rem;
}

.social-links > a:nth-child(1) {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin-top: .7rem;
}

.social-links > a:nth-child(2) {
    width: 3.5rem;
    height: 3.5rem;
    cursor: pointer;
    margin-top: -2.7rem;
    margin-left: 2.6rem;
}

.logo-footer > img {
    width: 9rem;
    margin-left: 5rem;
}

.logo-footer> p {
    color: #c9c7c7;
    font-size: 10px;
}


.logo-footer > .cref {
    font-size: 15px;
    font-weight: bold;
    margin-top: -1.5rem;
    margin-left: 5rem;
}

.logo-footer > a{ 
    color: var(--orange);
}

.footer-blur{
    bottom: 10;
    right: 25%;
    width: 70rem;
    height: 15rem;
    filter: blur(200px);
}