.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
    
}

.left-j>div{
    display: flex;
    gap: 2rem;
}

.right-j{
    min-height: 100vg;
    display: flex;
    width: 17rem;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    color: #FFF;
    margin-top: 50px;
    padding: 50px;
}

.title{
    margin-bottom: 14px;
}

.form-container{
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
}

.form-container>input{
    margin-bottom: 14px;
    height: 34px;
    border-radius: 4px;
    padding: 0 8px;
    border: none;
    outline: none;
}

.textarea{
    margin-bottom: 14px;
    border-radius: 4px;
    border: 0;
    padding: 8px;
    height: 94px;
    resize: none;
  }
  
  .button{
    height: 34px;
    border-radius: 4px;
    color: #fff;
    border: 0;
    cursor: pointer;
    background-color: var(--orange);
    font-size: 16px;
    transition: background-color, transform 0.9s;
    margin-top: 8px;
  }
  
  .button:hover{
    background-color: var(--orange);
    transform: scale(1.01);
  }

/* ::placeholder{
    color: var(--lightgray);
} */

/* .btn-j{
    background-color: var(--orange);
    color: #fff;
} */

@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
        text-align: center;
        padding: 1rem;
    }
    .left-j> div:nth-child(2){
        font-size:x-large;
        text-align: center;
        flex-direction: column;
        justify-content: center;
    }
    .left-j> div:nth-child(3){
        font-size:x-large;
        text-align: center;
        justify-content: center;
        
        padding: 1rem;
    }
    .left-j> div:nth-child(4){
        font-size:x-large;
        text-align: center;
        justify-content: center;

    }
    .left-j> div:nth-child(5){
        font-size:x-large;
        text-align: center;
        justify-content: center;

        
    }
    .right-j{
        padding: .3rem;
    }
}