.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 150px;
    /* height: 7rem; */
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: #fff;
    padding-top: 1.5rem;
    text-transform: uppercase;
}


.header-menu>li > a:hover{
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: color-mix(in srgb, var(--appColor) 10%, #fff 20%);
        padding: 2rem;
    }

    
}