.About{
    padding: 0 2rem;
    display: flex;
    gap: 6rem;
}

.left-r {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px) ;
    grid-template-rows: 200px 200px 200px;
    gap: 1rem;
    grid-template-areas:
    "foto1 foto2"
    "foto1 foto3"
    "foto4 foto5";
}


.left-r>img{
    object-fit: cover;
    height: 200px;
    width: 200px;
}

.left-r>:nth-child(1){
    grid-area: foto1;
    height: 410px;
}

.left-r>:nth-child(2){
    grid-area: foto2;
}

.left-r>:nth-child(3){
    grid-area: foto3;
}

.left-r>:nth-child(4){
    grid-area: foto4;
} 

.left-r>:nth-child(5){
    grid-area: foto5;
} 


.right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color: var(--orange);
}

.right-r>div{
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    /* margin-left: 3rem; */
}

.details-r>p {
    max-width: 750px;
    font-size: 1rem;
    text-transform:initial;
    font-weight: 400;
    line-height: 1.5;
    text-align:justify;
}

@media screen and (max-width: 768px) {
    .About{
        flex-direction: column;
    }
    .left-r {
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-r > :nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r > :nth-child(2){
        width: 8rem;
        height: 8.4rem;
        margin-left: -6rem;
    }
    .left-r > :nth-child(3){
        width: 8rem;
        height: 8rem;
        margin-top: -4.5rem;
        margin-left: -6rem;
    }
    .left-r > :nth-child(4){
        width: 7rem;
        height: 8rem;
        margin-top: -9.5rem;
    }
    .left-r > :nth-child(5){
        width: 8rem;
        height: 8rem;
        margin-top: -9.5rem;
        margin-left: -6rem;
    }
    .right-r{
        margin-top: -13rem;
    }
}